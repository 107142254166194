"use client";
import { createSlice } from "@reduxjs/toolkit";
import { IUser } from "../../../typings";

interface typeis {
  value: IUser | null;
}

const initialState: typeis = {
  value: null,
};

export const UserSlice = createSlice({
  name: "User",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { setUser } = UserSlice.actions;

export default UserSlice.reducer;
