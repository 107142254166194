"use client"
import { configureStore } from '@reduxjs/toolkit';
import User from '../Reducers/Users';
import Wallet from '../Reducers/Wallet';

export const store = configureStore({
  reducer:{
    User,
    Wallet,
  }
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch;