import React from "react";
import logo from "./logo.svg";
import { ReduxProvider } from "./redux/provider";
import { BrowserRouter, Link, Route, Routes } from "react-router-dom";
import LoginPage from "./app/LoginPage";
import DashboardLayout from "./app/layout/DashboardLayout";
import Dashboard from "./app/DashboardPages/Dashboard";
import AuthenticationLayOut from "./app/layout/AuthenticationLayOut";
import SignupPage from "./app/SignupPage";
import ForgetPassword from "./app/ForgetPassword";
import CreateNewPassword from "./app/CreateNewPassword";
import CoupansList from "./app/DashboardPages/CoupansList";

function App() {
  return (
    <ReduxProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/*" element={<DashboardLayout />}>
            <Route path="" element={<Dashboard />} />
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="coupans" element={<CoupansList />} />
            <Route path="coupans/:id" element={<CoupansList />} />
          </Route>
          <Route path={"/auth/*"} element={<AuthenticationLayOut />}>
            <Route path="login" element={<LoginPage />} />
            <Route path="signup" element={<SignupPage />} />
            <Route path="forget-password" element={<ForgetPassword />} />
            <Route
              path="create-new-password/:id"
              element={<CreateNewPassword />}
            />
          </Route>
        </Routes>
      </BrowserRouter>
    </ReduxProvider>
  );
}

export default App;
