import { ReactNode } from "react";
import {store} from "./store/store";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ThemeProvider as MtThemeProvider } from "@material-tailwind/react";

export function ReduxProvider({ children }: { children: ReactNode }) {
  return (
    <MtThemeProvider>
      <Provider store={store}>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
          {children}
      </Provider>
    </MtThemeProvider>
  );
}
