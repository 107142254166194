import { toast } from "react-toastify";

// export const BASE_URL = "https://souqarena.com/api";
export const BASE_URL = "http://localhost:3000/api";

//user
const SIGNUP = BASE_URL + "/affilate/auth/signup";
const LOGIN = BASE_URL + "/affilate/auth/login";
const LOGOUT = BASE_URL + "/affilate/auth/logout";
const HANDLE_AUTH = BASE_URL + "/affilate/auth";
//coupans
const HANDLE_COUPAN_ASSIGNED = BASE_URL + "/affilate/coupans";

//BUCKET
export const BUCKET_BASE_URL = "https://bucket.souqarena.com/api/v1";
const USER_IMAGE = BUCKET_BASE_URL + "/afuser";


//handleProfile
export const Signup = (data: any) => {
  return fetch(SIGNUP, {
    method: "POST",
    credentials: 'include',
    body: JSON.stringify(data),
  }).then((res) => {
    if (res.ok) {
      toast.success("Signup success.");
      return res.json();
    } else {
      toast.error("Failed to signup.");
      return null;
    }
  });
};
export const Login = (data: any) => {
  return fetch(LOGIN, {
    method: "POST",
    credentials: 'include',
    body: JSON.stringify(data),
  }).then((res) => {
    if (res.ok) {
      toast.success("Login success.");
      return res.json();
    } else {
      toast.error("Failed to login.");
      return null;
    }
  });
};
export const LogOut = () => {
  return fetch(LOGOUT, {
    method: "Get",
    credentials: 'include',
  }).then((res) => {
    if (res.ok) {
      toast.success("Logout success.");
      return res.json();
    } else {
      toast.error("Failed to Logout.");
      return null;
    }
  });
};
export const GetUser = () => {
  return fetch(LOGIN, {
    method: "GET",
    credentials: 'include'
    // headers:{...headers}
  }).then((res) => {
    if (res.ok) {
      return res.json();
    } else {
      return null;
    }
  });
};
export const SetUserImage = (data: any) => {
  return fetch(USER_IMAGE, {
    method: "POST",
    credentials: 'include',
    body: data,
  }).then((res) => {
    if (res.ok) {
      return res.json();
    } else {
      return null;
    }
  });
};
export const UpdateUser = (data: any) => {
  return fetch(HANDLE_AUTH, {
    method: "PUT",
    credentials: 'include',
    body: JSON.stringify(data),
  }).then((res) => {
    if (res.ok) {
      toast.success("User updated success.");
      return res.json();
    } else {
      toast.error("Failed to update.");
      return null;
    }
  });
};

//coupans assigned
export const GetCoupans = () => {
  return fetch(HANDLE_COUPAN_ASSIGNED, {
    method: "GET",
    credentials: 'include'
    // headers:{...headers}
  }).then((res) => {
    console.log(res);
    
    if (res.ok) {
      return res.json();
    } else {
      return null;
    }
  });
};
