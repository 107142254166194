import { Button, Input } from "@material-tailwind/react";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Login } from "../utils/Api";
import { useDispatch } from "react-redux";
import { setUser } from "../redux/Reducers/Users";
import { AiOutlineEyeInvisible, AiOutlineEye } from "react-icons/ai";
import { useNavigate } from "react-router-dom";

export default function LoginPage() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordInputType, setPasswordInputType] = useState("password");

  const Dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogin = (e: any) => {
    e.preventDefault();
    let data = {
      email,
      password,
    };
    Login(data).then((e) => {
      if (e != null) {
        Dispatch(setUser(e.data));
        navigate("/")
      }
    });
  };
  const handlePasswordInput = () => {
    if (passwordInputType == "password") {
      setPasswordInputType("text");
    } else {
      setPasswordInputType("password");
    }
  };

  const handleForgetPassword=()=>{
    navigate("/auth/forget-password")
  }
  const handleSignUp=()=>{
    navigate("/auth/signup")
  }
  return (
    <div>
      <Helmet>
        <title>Login | Affilate Souqarena</title>
        <meta name="description" content="Affilate Login Souqarena" />
      </Helmet>
      <div className="w-screen h-screen flex items-center justify-center">
        <div className="h-full w-full max-w-screen-sm flex flex-col items-center space-y-2">
          <img
            src="/logo.svg"
            alt="logo-image"
            width={300}
            height={150}
            className="mt-4"
          />
          <form
            className="mt-8 space-y-6 w-full h-full flex flex-col items-center justify-center p-4"
            onSubmit={handleLogin}
          >
            <div className="w-full rounded-md shadow-sm space-y-4">
              <div className="w-full">
                <label htmlFor="username" className="sr-only">
                  Username
                </label>
                <div className="relative">
                  <Input
                    crossOrigin=""
                    name="username"
                    type="text"
                    label="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>
              </div>
              <div>
                <label htmlFor="password" className="sr-only">
                  Password
                </label>
                <div className="relative">
                  <Input
                    icon={
                      passwordInputType == "password" ? (
                        <AiOutlineEye onClick={handlePasswordInput} />
                      ) : (
                        <AiOutlineEyeInvisible onClick={handlePasswordInput} />
                      )
                    }
                    crossOrigin=""
                    label="Password"
                    name="password"
                    type={passwordInputType}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                </div>
              </div>
            </div>
            <div className="w-full flex items-center justify-between">
              <Button placeholder={""} onClick={handleForgetPassword} color="red" variant="text">
                Forget password
              </Button>
              <Button placeholder={""} type="submit" color="blue">
                Log in
              </Button>
            </div>
            <hr className="py-2" />
            <div className="flex items-center justify-center">
              <Button placeholder={""} onClick={handleSignUp} color="green" variant="text">
                Sign up
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
