import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import {
  Button,
  Card,
  CardBody,
  IconButton,
  Menu,
  MenuHandler,
  MenuItem,
  MenuList,
  Option,
  Select,
  Typography,
} from "@material-tailwind/react";
import LoadingCompo from "../components/loadingCompo";
import { ChevronDoubleDownIcon } from "@heroicons/react/24/solid";
import { TbCoins } from "react-icons/tb";
import { MdOutlinePaid } from "react-icons/md";
import { PiHandCoins, PiUsersThree, PiShoppingBagLight } from "react-icons/pi";
import { AiOutlineStock } from "react-icons/ai";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store/store";
import { IUser } from "../../../typings";

const FILTERS = [
  "Last 24 hour",
  "Last week",
  "Last month",
  "Last 3 months",
  "Last 6 months",
  "Last year",
];

export default function Dashboard() {
  const User = useSelector((state: RootState) => state.User.value);
  const [user, setUser] = useState<IUser>();
  const [isLoading, setIsLoading] = useState(false);
  const [reloadData, setRelaodData] = useState(false);
  const [avrageOrder, setAvrageOrder] = useState(0);
  const [lastOrders, setLastOrder] = useState<any[]>([]);

  const [totalEarning, setTotalEarning] = useState(0);
  const [paidAmmount, setPaidAmmount] = useState(0);
  const [unPaidAnnmount, setUnPainAmmount] = useState(0);
  const [visitors, setVisitors] = useState(0);
  const [customer, setCostumer] = useState(0);
  const [convertion, setConvertion] = useState(0);

  const [isOpenSalesMenu, setOpenSalesMenu] = useState(false);
  const [isOpenOrderMenu, setOpenOrderMenu] = useState(false);
  const [isOpenCustomerMenu, setOpenCustomerMenu] = useState(false);
  const [isOpenProdcutsMenu, setOpenProuctsMenu] = useState(false);

  const [selectedSaleFilter, setSelectedSaleFilter] =
    useState<any>("Last 24 hour");
  const [selectedOrderFilter, setSelectedOrderFilter] =
    useState<any>("Last 24 hour");
  const [selectedNewCustomerFilter, setSelectedNewCustomerFilter] =
    useState<any>("Last 24 hour");
  const [selectedProductFilter, setSelectedProductsFilter] =
    useState<any>("Last 24 hour");

  useEffect(() => {    
    if (User != null) {
      setUser(User);
    }
  }, [User]);

  const GetQuanitiy = (data: any) => {
    let _qunatity = 0;
    data.products.forEach((_data: any) => {
      _qunatity = _qunatity + _data.quantity;
    });
    return _qunatity;
  };
  const GetTotalSingleOrder = (data: any) => {
    let _total = 0;
    data.products.forEach((_data: any) => {
      if (_data.variationData != null) {
        _total = _total + _data.variationData.variation.discounted_price;
      } else {
        _total = _total + _data.productData.product.discounted_price;
      }
    });
    return _total;
  };
  return (
    <div className="w-full h-full">
      <Helmet>
        <title>Dashboard | Affilate Souqarena</title>
        <meta name="description" content="Affilate Dashboard Souqarena" />
      </Helmet>
      <div className="w-full h-full p-4 space-y-4 ">
        {isLoading && (
          <div>
            <LoadingCompo />
          </div>
        )}
        <div></div>
        <div className="flex items-center justify-between ">
          <Typography variant="h3">Dashboard</Typography>
          <div className="flex items-center space-x-2">
            <Typography variant="h6">Affilate id:{user?.affilateId}</Typography>
            <Button
              // color="deep-orange"
              className="bg-souq-blue"
              onClick={() => setRelaodData((pre) => !pre)}
            >
              Reload data
            </Button>
          </div>
        </div>
        <hr />
        {/* headers */}
        <div className="grid grid-cols-4 gap-2">
          <Card>
            <CardBody>
              <div className=" space-y-4">
                <div className="w-full flex items-center justify-between">
                  <div>
                    <Typography variant="h5">Total earning</Typography>
                    <div className="flex items-center space-x-2">
                      <Typography variant="paragraph">AED</Typography>
                      <Typography variant="paragraph" color="green">
                        {totalEarning}
                      </Typography>
                    </div>
                  </div>
                  <div>
                    <IconButton color="red" className="rounded-full" size="lg">
                      <TbCoins size={35} />
                    </IconButton>
                  </div>
                </div>
                <div className="flex items-center justify-between">
                  <div></div>
                  <div className="">
                    <Menu
                      open={isOpenSalesMenu}
                      handler={setOpenSalesMenu}
                      allowHover
                    >
                      <MenuHandler>
                        <Button
                          variant="text"
                          className="flex items-center gap-3  font-normal capitalize tracking-normal text-xs"
                        >
                          {selectedSaleFilter}{" "}
                          <ChevronDoubleDownIcon
                            strokeWidth={2.5}
                            className={`h-3.5 w-3.5 transition-transform ${
                              isOpenSalesMenu ? "rotate-180" : ""
                            }`}
                          />
                        </Button>
                      </MenuHandler>
                      <MenuList className="max-h-72">
                        {FILTERS.map((data, index) => (
                          <MenuItem
                            key={index}
                            onClick={() => setSelectedSaleFilter(data)}
                          >
                            {data}
                          </MenuItem>
                        ))}
                      </MenuList>
                    </Menu>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <div className=" space-y-4">
                <div className="w-full flex items-center justify-between">
                  <div>
                    <Typography variant="h5">Paid Ammount</Typography>
                    <div className="flex items-center space-x-2">
                      <Typography variant="paragraph">AED</Typography>
                      <Typography variant="paragraph" color="green">
                        {paidAmmount}
                      </Typography>
                    </div>
                  </div>
                  <div>
                    <IconButton
                      color="yellow"
                      className="rounded-full"
                      size="lg"
                    >
                      <MdOutlinePaid size={35} className="text-yellow-900" />
                    </IconButton>
                  </div>
                </div>
                <div className="flex items-center justify-between">
                  <div></div>
                  <div className="">
                    <Menu
                      open={isOpenOrderMenu}
                      handler={setOpenOrderMenu}
                      allowHover
                    >
                      <MenuHandler>
                        <Button
                          variant="text"
                          className="flex items-center gap-3  font-normal capitalize tracking-normal text-xs"
                        >
                          {selectedOrderFilter}{" "}
                          <ChevronDoubleDownIcon
                            strokeWidth={2.5}
                            className={`h-3.5 w-3.5 transition-transform ${
                              isOpenOrderMenu ? "rotate-180" : ""
                            }`}
                          />
                        </Button>
                      </MenuHandler>
                      <MenuList className="max-h-72">
                        {FILTERS.map((data, index) => (
                          <MenuItem
                            key={index}
                            onClick={() => setSelectedOrderFilter(data)}
                          >
                            {data}
                          </MenuItem>
                        ))}
                      </MenuList>
                    </Menu>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <div className=" space-y-4">
                <div className="w-full flex items-center justify-between">
                  <div>
                    <Typography variant="h5">Unpaid Ammount</Typography>
                    <div className="flex items-center space-x-2">
                      <Typography variant="paragraph">AED</Typography>
                      <Typography variant="paragraph" color="green">
                        {unPaidAnnmount}
                      </Typography>
                    </div>
                  </div>
                  <div>
                    <IconButton
                      color="light-blue"
                      className="rounded-full"
                      size="lg"
                    >
                      <PiHandCoins size={35} className="text-blue-900" />
                    </IconButton>
                  </div>
                </div>
                <div className="flex items-center justify-between">
                  <div></div>
                  <div className="">
                    <Menu
                      open={isOpenCustomerMenu}
                      handler={setOpenCustomerMenu}
                      allowHover
                    >
                      <MenuHandler>
                        <Button
                          variant="text"
                          className="flex items-center gap-3  font-normal capitalize tracking-normal text-xs"
                        >
                          {selectedNewCustomerFilter}{" "}
                          <ChevronDoubleDownIcon
                            strokeWidth={2.5}
                            className={`h-3.5 w-3.5 transition-transform ${
                              isOpenCustomerMenu ? "rotate-180" : ""
                            }`}
                          />
                        </Button>
                      </MenuHandler>
                      <MenuList className="max-h-72">
                        {FILTERS.map((data, index) => (
                          <MenuItem
                            key={index}
                            onClick={() => setSelectedNewCustomerFilter(data)}
                          >
                            {data}
                          </MenuItem>
                        ))}
                      </MenuList>
                    </Menu>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <div className=" space-y-4">
                <div className="w-full flex items-center justify-between">
                  <div>
                    <Typography variant="h5">To Be ADDED</Typography>
                    <div className="flex items-center space-x-2">
                      {/* <Typography variant="paragraph">AED</Typography> */}
                      <Typography variant="paragraph" color="green">
                        0
                      </Typography>
                    </div>
                  </div>
                  <div>
                    <IconButton color="pink" className="rounded-full" size="lg">
                      <PiUsersThree size={35} className="text-pink-900" />
                    </IconButton>
                  </div>
                </div>
                <div className="flex items-center justify-between">
                  <div></div>
                  <div className="">
                    <Menu
                      open={isOpenProdcutsMenu}
                      handler={setOpenProuctsMenu}
                      allowHover
                    >
                      <MenuHandler>
                        <Button
                          variant="text"
                          className="flex items-center gap-3  font-normal capitalize tracking-normal text-xs"
                        >
                          {selectedProductFilter}{" "}
                          <ChevronDoubleDownIcon
                            strokeWidth={2.5}
                            className={`h-3.5 w-3.5 transition-transform ${
                              isOpenProdcutsMenu ? "rotate-180" : ""
                            }`}
                          />
                        </Button>
                      </MenuHandler>
                      <MenuList className="max-h-72">
                        {FILTERS.map((data, index) => (
                          <MenuItem
                            key={index}
                            onClick={() => setSelectedProductsFilter(data)}
                          >
                            {data}
                          </MenuItem>
                        ))}
                      </MenuList>
                    </Menu>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <div className=" space-y-4">
                <div className="w-full flex items-center justify-between">
                  <div>
                    <Typography variant="h5">Visitors</Typography>
                    <div className="flex items-center space-x-2">
                      {/* <Typography variant="paragraph">AED</Typography> */}
                      <Typography variant="paragraph" color="green">
                        {visitors}
                      </Typography>
                    </div>
                  </div>
                  <div>
                    <IconButton color="pink" className="rounded-full" size="lg">
                      <PiUsersThree size={35} className="text-pink-900" />
                    </IconButton>
                  </div>
                </div>
                {/* <div className="flex items-center justify-between">
                  <div></div>
                  <div className="">
                    <Menu
                      open={isOpenProdcutsMenu}
                      handler={setOpenProuctsMenu}
                      allowHover
                    >
                      <MenuHandler>
                        <Button
                          variant="text"
                          className="flex items-center gap-3  font-normal capitalize tracking-normal text-xs"
                        >
                          {selectedProductFilter}{" "}
                          <ChevronDoubleDownIcon
                            strokeWidth={2.5}
                            className={`h-3.5 w-3.5 transition-transform ${
                              isOpenProdcutsMenu ? "rotate-180" : ""
                            }`}
                          />
                        </Button>
                      </MenuHandler>
                      <MenuList className="max-h-72">
                        {FILTERS.map((data, index) => (
                          <MenuItem
                            key={index}
                            onClick={() => setSelectedProductsFilter(data)}
                          >
                            {data}
                          </MenuItem>
                        ))}
                      </MenuList>
                    </Menu>
                  </div>
                </div> */}
              </div>
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <div className=" space-y-4">
                <div className="w-full flex items-center justify-between">
                  <div>
                    <Typography variant="h5">Customers</Typography>
                    <div className="flex items-center space-x-2">
                      {/* <Typography variant="paragraph">AED</Typography> */}
                      <Typography variant="paragraph" color="green">
                        {customer}
                      </Typography>
                    </div>
                  </div>
                  <div>
                    <IconButton color="teal" className="rounded-full" size="lg">
                      <PiShoppingBagLight
                        size={35}
                        className=" text-teal-200"
                      />
                    </IconButton>
                  </div>
                </div>
                {/* <div className="flex items-center justify-between">
                  <div></div>
                  <div className="">
                    <Menu
                      open={isOpenProdcutsMenu}
                      handler={setOpenProuctsMenu}
                      allowHover
                    >
                      <MenuHandler>
                        <Button
                          variant="text"
                          className="flex items-center gap-3  font-normal capitalize tracking-normal text-xs"
                        >
                          {selectedProductFilter}{" "}
                          <ChevronDoubleDownIcon
                            strokeWidth={2.5}
                            className={`h-3.5 w-3.5 transition-transform ${
                              isOpenProdcutsMenu ? "rotate-180" : ""
                            }`}
                          />
                        </Button>
                      </MenuHandler>
                      <MenuList className="max-h-72">
                        {FILTERS.map((data, index) => (
                          <MenuItem
                            key={index}
                            onClick={() => setSelectedProductsFilter(data)}
                          >
                            {data}
                          </MenuItem>
                        ))}
                      </MenuList>
                    </Menu>
                  </div>
                </div> */}
              </div>
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <div className=" space-y-4">
                <div className="w-full flex items-center justify-between">
                  <div>
                    <Typography variant="h5">Conversion</Typography>
                    <div className="flex items-center space-x-2">
                      {/* <Typography variant="paragraph">AED</Typography> */}
                      <Typography variant="paragraph" color="green">
                        {convertion}
                      </Typography>
                    </div>
                  </div>
                  <div>
                    <IconButton color="lime" className="rounded-full" size="lg">
                      <AiOutlineStock size={35} className=" text-lime-100" />
                    </IconButton>
                  </div>
                </div>
                {/* <div className="flex items-center justify-between">
                  <div></div>
                  <div className="">
                    <Menu
                      open={isOpenProdcutsMenu}
                      handler={setOpenProuctsMenu}
                      allowHover
                    >
                      <MenuHandler>
                        <Button
                          variant="text"
                          className="flex items-center gap-3  font-normal capitalize tracking-normal text-xs"
                        >
                          {selectedProductFilter}{" "}
                          <ChevronDoubleDownIcon
                            strokeWidth={2.5}
                            className={`h-3.5 w-3.5 transition-transform ${
                              isOpenProdcutsMenu ? "rotate-180" : ""
                            }`}
                          />
                        </Button>
                      </MenuHandler>
                      <MenuList className="max-h-72">
                        {FILTERS.map((data, index) => (
                          <MenuItem
                            key={index}
                            onClick={() => setSelectedProductsFilter(data)}
                          >
                            {data}
                          </MenuItem>
                        ))}
                      </MenuList>
                    </Menu>
                  </div>
                </div> */}
              </div>
            </CardBody>
          </Card>
        </div>
        {/* charts */}
        <div className="grid grid-cols-2 gap-4"></div>
        <div className="col-span-2 "></div>
      </div>
    </div>
  );
}
