import React, { useEffect, useState } from "react";
import {
  Card,
  Typography,
  List,
  ListItem,
  ListItemPrefix,
  ListItemSuffix,
  Chip,
  Accordion,
  AccordionHeader,
  AccordionBody,
  Alert,
  Button,
  IconButton,
} from "@material-tailwind/react";
import { PresentationChartBarIcon } from "@heroicons/react/24/solid";
import { FaStore } from "react-icons/fa";
import * as BiIcons from "react-icons/bi";
import * as AiIcons from "react-icons/ai";
import { AdminSidbarItemsType } from "../../../typings";
// import { version } from "../../../package.json";
import { IoIosClose } from "react-icons/io";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store/store";

export default function AdminDashboardSideBar() {
  const User = useSelector((state: RootState) => state.User.value);
  const router = useNavigate();
  const [open, setOpen] = useState<any>();
  const [visibility, setVisbility] = useState<boolean>(true);
  const [SideBarDataAdmin, setSideBarAdmin] = useState<
    Array<AdminSidbarItemsType>
  >([
    {
      name: "Dashboard",
      icon: <PresentationChartBarIcon className="h-5 w-5" />,
      to: `/`,
      children: null,
    },
  ]);

  useEffect(() => {
    if (User != null) {
      if (User.role == "company") {
        setSideBarAdmin([
          {
            name: "Dashboard",
            icon: <PresentationChartBarIcon className="h-5 w-5" />,
            to: `/`,
            children: null,
          },
          {
            name: "Coupans",
            icon: <BiIcons.BiPackage className="h-5 w-5" />,
            children: [
              {
                name: "Coupans",
                icon: <BiIcons.BiSitemap className="h-5 w-5" />,
                children: [
                  {
                    name: "All Coupans",
                    icon: <BiIcons.BiSitemap className="h-5 w-5" />,
                    to: `/coupans`,
                  },
                  // {
                  //   name: "Redeemed Coupans",
                  //   icon: <BiIcons.BiSitemap className="h-5 w-5" />,
                  //   to: `/admin/orders?type=1`,
                  // },
                ],
              },
            ],
            to: null,
          },
        ]);
      }
    }
  }, [User]);

  const handleOpen = (index: number) => {
    setOpen(index);
    setVisbility(true);
  };
  const handleBack = () => {
    router("/");
  };
  return (
    <Card className="h-screen w-full max-w-[8rem] p-4 rounded-none shadow-xl shadow-blue-gray-900/5 bg-gray-900 relative">
      <div
        className="mb-2 flex items-center bg-white rounded-full cursor-pointer"
        onClick={handleBack}
      >
        <img
          src="/sa-logo.svg"
          alt="brand"
          className="h-max w-full"
          width={200}
          height={200}
        />
        {/* <Typography variant="h5" color="blue-gray">
          Sidebar
        </Typography> */}
      </div>
      {SideBarDataAdmin.map((data, index) => (
        <SiderBarRednred
          key={index}
          data={data}
          index={index}
          isActive={open}
          visibility={visibility}
          setVisbility={() => setVisbility((pre) => !pre)}
          setActive={handleOpen}
        />
      ))}
      <div className=" absolute left-0 bottom-0  w-full h-10 flex items-center justify-center">
        <Typography variant="h6" color="gray" className="text-xs">
          {/* Version:{version} */}
          Version:0.1.0
        </Typography>
      </div>
    </Card>
  );
}

const SiderBarRednred = ({
  data,
  index,
  setActive,
  isActive,
  visibility,
  setVisbility,
}: {
  data: AdminSidbarItemsType;
  index: number;
  setActive: (index: number) => void;
  isActive: number;
  visibility: boolean;
  setVisbility: () => void;
}) => {
  return (
    <div>
      {data.children == null ? (
        <div>
          <Link
            to={data.to}
            className={`flex flex-col items-center justify-center max-w-[6rem] hover:bg-gray-700 active:bg-gray-100 p-3 rounded-lg ${
              isActive == index ? "bg-gray-700" : ""
            }`}
            onClick={() => setActive(index)}
          >
            <ListItemPrefix className="text-white m-0 p-0 ">
              {data.icon}
            </ListItemPrefix>
            <Typography color="white" className="font-normal text-xs">
              {data.name}
            </Typography>
          </Link>
        </div>
      ) : (
        <div>
          <Button
            className={`flex flex-col items-center justify-center w-[6rem] hover:bg-gray-700 active:bg-gray-100 ${
              isActive == index ? "bg-gray-700" : ""
            }`}
            onClick={() => setActive(index)}
          >
            <ListItemPrefix className="text-white m-0 p-0 ">
              {data.icon}
            </ListItemPrefix>
            <Typography color="white" className="font-normal text-xs">
              {data.name}
            </Typography>
          </Button>
          {isActive == index && visibility && (
            <SiderbarChildrensRendrer
              data={data}
              handleClose={() => setVisbility()}
            />
          )}
        </div>
      )}
    </div>
  );
};

const SiderbarChildrensRendrer = ({
  data,
  handleClose,
}: {
  data: AdminSidbarItemsType;
  handleClose: () => void;
}) => {
  return (
    <div className=" absolute h-full w-max px-2 bg-gray-800 z-50 top-0 left-full flex flex-col ">
      <div className="px-4 py-2 flex items-center justify-between">
        <h3 className="text-2xl text-white font-bold">{data.name}</h3>
        <div>
          <IconButton variant="text" onClick={() => handleClose()}>
            <IoIosClose size={25} color="white" />
          </IconButton>
        </div>
      </div>
      <div className="grid grid-cols-2 gap-2 text-gray-500">
        {data.children?.map((d, index) => (
          <div key={index}>
            {d.children != null ? (
              <div className="p-3">
                <h2 className="font-bold text-lg">{d.name}</h2>
                <div className="flex flex-col">
                  {d.children.map((ds, index) => (
                    <Link
                      key={index}
                      to={ds.to}
                      onClick={handleClose}
                      className="py-2 px-3 hover:bg-gray-700 rounded-lg text-white"
                    >
                      {ds.name}
                    </Link>
                  ))}
                </div>
              </div>
            ) : (
              <div className="p-3">
                <h2 className="font-bold">{d.name}</h2>
                <Link to={d.to}>{d.name}</Link>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};
