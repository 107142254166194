import React from "react";

export default function LoadingCompo({type}:{type?:boolean}) {
  return (
   <div className={`w-full ${type?"":"h-screen"} flex items-center justify-center`}>
     <svg
      xmlns="http://www.w3.org/2000/svg"
      width="50"
      height="50"
      viewBox="0 0 50 50"
    >
      <circle
        cx="25"
        cy="25"
        r="20"
        fill="transparent"
        stroke="#3498db"
        strokeWidth="4"
      >
        <animate
          attributeName="r"
          from="10"
          to="20"
          dur="0.8s"
          begin="0s"
          repeatCount="indefinite"
        />
        <animate
          attributeName="opacity"
          from="1"
          to="0"
          dur="0.8s"
          begin="0s"
          repeatCount="indefinite"
        />
      </circle>
    </svg>
   </div>
  );
}
