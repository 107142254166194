"use client"
import {createSlice}from "@reduxjs/toolkit"
import { IWallet } from "../../../typings"

interface typeis{
  value:IWallet|null
}

const initialState:typeis = {
  value:null
}

export const slice = createSlice({
  name:"Wallets",
  initialState,
  reducers:{
    setWallet:(state,action)=>{
      state.value=action.payload
    }
  }
}) 


export const {setWallet}=slice.actions

export default slice.reducer;
