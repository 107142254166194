import React, { useState } from "react";
import { Helmet } from "react-helmet";
import {
  Button,
  Input,
  Option,
  Select,
  Typography,
} from "@material-tailwind/react";
import { Login, SetUserImage, Signup } from "../utils/Api";
import { useDispatch } from "react-redux";
import { setUser } from "../redux/Reducers/Users";
import { AiOutlineEyeInvisible, AiOutlineEye } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const affilate_type = ["company", "freelancer"];

export default function SignupPage() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confiremPassword, setConfirmPassword] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [dpFile, setDpFile] = useState<File>();
  const [dpUrl, setDpUrl] = useState("");
  const [passwordInputType, setPasswordInputType] = useState("password");
  const [ConfirmedpasswordInputType, setConfirmedpasswordInputType] =
    useState("password");
  const [affilateType, setAffilateType] = useState<string>();

  const Dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSignup = (e: any) => {
    e.preventDefault();
    // if (affilateType == null) {
    //   toast.error("Please select a affilate type");
    //   return;
    // }
    // if (password != confiremPassword) {
    //   toast.warn("Confirm password not matched with Password.");
    //   return;
    // }
    // // uploading file
    // if (dpFile == null) {
    //   toast.error("Please select a dp");
    //   return;
    // }
    let data = {
      username: name,
      dp: "",
      email,
      mobile_number: mobileNumber,
      companyName: companyName,
      password: password,
      role: affilateType,
    };
    Signup(data).then((e) => {
      if (e != null) {
        Dispatch(setUser(e.data));
      }
    });
    // const formData = new FormData();
    // formData.append("file", dpFile);

    // SetUserImage(formData).then((res) => {
    //   if (res != null) {
    //     let data = {
    //       username: name,
    //       dp: res.data.file,
    //       email,
    //       mobile_number: mobileNumber,
    //       companyName: companyName,
    //       password: password,
    //       role: affilateType,
    //     };
    //     Signup(data).then((e) => {
    //       if (e != null) {
    //         Dispatch(setUser(e.data));
    //       }
    //     });
    //   }
    // });
  };
  const handlePasswordInput = () => {
    if (passwordInputType == "password") {
      setPasswordInputType("text");
    } else {
      setPasswordInputType("password");
    }
  };
  const handleConfirmPasswordInput = () => {
    if (ConfirmedpasswordInputType == "password") {
      setConfirmedpasswordInputType("text");
    } else {
      setConfirmedpasswordInputType("password");
    }
  };

  const handleLogin = () => {
    navigate("/auth/login");
  };
  return (
    <div>
      <Helmet>
        <title>Signup | Affilate Souqarena</title>
        <meta name="description" content="Affilate Signup Souqarena" />
      </Helmet>
      <div className="w-screen h-screen flex items-center justify-center">
        <div className="h-full w-full max-w-screen-sm flex flex-col items-center space-y-2">
          <img
            src="/logo.svg"
            alt="logo-image"
            width={300}
            height={150}
            className="mt-4"
          />
          <form
            className="mt-8 space-y-6 w-full h-full flex flex-col items-center justify-center p-4"
            onSubmit={handleSignup}
          >
            <div className="w-full rounded-md shadow-sm space-y-4">
              <div className="w-full">
                <label htmlFor="username" className="sr-only">
                  Affilate type
                </label>
                <div className="relative">
                  <Select
                    placeholder={""}
                    name="username"
                    label="Select Affilate Type"
                    value={affilateType}
                    onChange={(e) => setAffilateType(e != null ? e : "")}
                  >
                    {affilate_type.map((data, index) => (
                      <Option key={index} value={data}>
                        {data}
                      </Option>
                    ))}
                  </Select>
                </div>
              </div>
              {affilateType == "company" && (
                <div className=" space-y-4">
                  <div className="w-full">
                    <label htmlFor="username" className="sr-only">
                      Company name
                    </label>
                    <div className="relative">
                      <Input
                        crossOrigin=""
                        name="company-name"
                        type="text"
                        label="Company Name"
                        value={companyName}
                        onChange={(e) => setCompanyName(e.target.value)}
                        required
                      />
                    </div>
                  </div>
                  <div className="w-full">
                    <label htmlFor="username" className="sr-only">
                      Contact person name
                    </label>
                    <div className="relative">
                      <Input
                        crossOrigin=""
                        name="name"
                        type="text"
                        label="Contact person name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                      />
                    </div>
                  </div>
                </div>
              )}
              {affilateType != "company" && (
                <div className="w-full">
                  <label htmlFor="username" className="sr-only">
                    Name
                  </label>
                  <div className="relative">
                    <Input
                      crossOrigin=""
                      name="name"
                      type="text"
                      label="Name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      required
                    />
                  </div>
                </div>
              )}
              <div className="w-full">
                <label htmlFor="username" className="sr-only">
                  Email
                </label>
                <div className="relative">
                  <Input
                    crossOrigin=""
                    name="username"
                    type="email"
                    label="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>
              </div>
              <div>
                <label htmlFor="password" className="sr-only">
                  Mobile number
                </label>
                <div className="relative">
                  <Input
                    crossOrigin=""
                    label="Mobile number"
                    name="mobile_number"
                    type="tel"
                    value={mobileNumber}
                    onChange={(e) => setMobileNumber(e.target.value)}
                    required
                  />
                </div>
              </div>
              <div>
                <label htmlFor="password" className="sr-only">
                  Password
                </label>
                <div className="relative">
                  <Input
                    icon={
                      passwordInputType == "password" ? (
                        <AiOutlineEye onClick={handlePasswordInput} />
                      ) : (
                        <AiOutlineEyeInvisible onClick={handlePasswordInput} />
                      )
                    }
                    crossOrigin=""
                    label="Password"
                    name="password"
                    type={passwordInputType}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                </div>
              </div>
              <div>
                <label htmlFor="password" className="sr-only">
                  Confirm Password
                </label>
                <div className="relative">
                  <Input
                    icon={
                      passwordInputType == "password" ? (
                        <AiOutlineEye onClick={handleConfirmPasswordInput} />
                      ) : (
                        <AiOutlineEyeInvisible
                          onClick={handleConfirmPasswordInput}
                        />
                      )
                    }
                    crossOrigin=""
                    label="Confrim Password"
                    name="confirm password"
                    type={ConfirmedpasswordInputType}
                    value={confiremPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    required
                  />
                </div>
              </div>
            </div>
            <div className="w-full flex items-center justify-between">
              <Button placeholder={""} type="submit" color="blue" fullWidth>
                Sign up
              </Button>
            </div>
            <hr className="py-2" />
            <div className="flex items-center justify-center">
              <Typography placeholder={""} variant="lead" className="text-xs">
                Already an account{" "}
              </Typography>
              <Button
                placeholder={""}
                onClick={handleLogin}
                color="green"
                variant="text"
              >
                Login
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
