import React, { ReactNode, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";
import { RootState } from "../../redux/store/store";
import { GetUser } from "../../utils/Api";
import { setUser } from "../../redux/Reducers/Users";
import  AdminDashboardSideBar from "../DashboardPages/Sidebar";
export default function DashboardLayout() {
  const User = useSelector((state:RootState)=>state.User.value);
  const Dispatch=useDispatch();
  const navigate=useNavigate();
  useLayoutEffect(()=>{
    if(User!=null){

    }else{
      GetUser().then(res=>{
        if(res!=null){
          Dispatch(setUser(res.data))
        }else{
          navigate("/auth/login")
        }
      })
    }
  },[])
  return (
    <div className="flex">
      <AdminDashboardSideBar/>
      <Outlet />
    </div>
  );
}
