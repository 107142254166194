import React, { useEffect, useLayoutEffect, useState } from "react";
import { PencilIcon } from "@heroicons/react/24/solid";
import {
  ArrowDownTrayIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/outline";
import { AiOutlineEye } from "react-icons/ai";
import { MdDeleteOutline } from "react-icons/md";
import {
  Card,
  CardHeader,
  Typography,
  Button,
  CardBody,
  Chip,
  CardFooter,
  Avatar,
  IconButton,
  Tooltip,
  Input,
  ButtonGroup,
} from "@material-tailwind/react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { ICoupanAssigned } from "../../../typings";
import { GetCoupans } from "../../utils/Api";

const TABLE_HEAD = [
  "Title",
  "From",
  "To",
  "Date",
  "",
];

export default function CoupansList() {
  const router = useNavigate();
  const Dispatch = useDispatch();
  const [products, setProductsValue] = useState<Array<ICoupanAssigned>>([]);
  const [page, setPage] = useState(1);

  const itemsPerPage = 8;
  const [totalPages, setTotalPages] = useState(1);
  const [paginatedData, setPaginatedData] = useState<Array<ICoupanAssigned>>(
    []
  );

  const [search, setSearch] = useState("");
  const [filteredData, setFilterData] = useState<Array<ICoupanAssigned>>([]);

  useLayoutEffect(() => {
    GetCoupans().then(res=>{
        console.log(res);
        
        if(res!=null){
            setProductsValue(res.data)
        }
    })
  }, []);

  //handling seaches
  useEffect(() => {
    if (products?.length > 0) {
      if (search == "") {
        setFilterData(products);
        setTotalPages(Math.ceil(products.length / itemsPerPage));
      } else {
        let _stockData = [...products];
        let nameFilter: ICoupanAssigned[] = [];
        nameFilter = _stockData.filter((data) => data.name.includes(search));

        setFilterData(nameFilter);
        setTotalPages(Math.ceil(nameFilter.length / itemsPerPage));
      }
    }
  }, [search, products]);
  const handlePreViews = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const handleForword = () => {
    if (page < totalPages) {
      setPage(page + 1);
    }
  };
  const handleDate = (date: Date) => {
    return moment(date).format("DD/MM/yyyy");
  };

  useEffect(() => {
    if (filteredData.length > 0) {
      const startIndex = (page - 1) * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;
      const paginatedData = filteredData.slice(startIndex, endIndex);
      setPaginatedData(paginatedData);
    }
  }, [page, filteredData]);

  const handleViewCodes = (data: any) => {
    
  };
  return (
    <Card className="h-max w-full ">
      <CardHeader floated={false} shadow={false} className="rounded-none">
        <div className="mb-4 flex flex-col justify-between gap-8 md:flex-row md:items-center">
          <div>
            <Typography variant="h5" color="blue-gray">
              Coupans list
            </Typography>
            <Typography color="gray" className="mt-1 font-normal">
              These are all coupans and details.
            </Typography>
          </div>
          <div className="flex w-full shrink-0 gap-2 md:w-max">
            <div className="w-full md:w-72">
              <Input
                crossOrigin=""
                label="Search"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                icon={<MagnifyingGlassIcon className="h-5 w-5" />}
              />
            </div>
          </div>
        </div>
      </CardHeader>
      <CardBody className=" p-4 ">
        <table className="w-full min-w-max table-auto text-left">
          <thead>
            <tr>
              {TABLE_HEAD.map((head) => (
                <th
                  key={head}
                  className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4"
                >
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal leading-none opacity-70"
                  >
                    {head}
                  </Typography>
                </th>
              ))}
            </tr>
          </thead>
          <tbody key={"dsdsd"}>
            {paginatedData?.map((data, index) => {
              const isLast = index === paginatedData.length - 1;
              const classes = isLast
                ? "p-4"
                : "p-4 border-b border-blue-gray-50";

              return (
                <>
                  <tr key={index}>
                    <td className={classes}>
                      <div className="flex items-center gap-3">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-bold"
                        >
                          {data.name}
                        </Typography>
                      </div>
                    </td>
                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal"
                      >
                        {data.from.code}
                        {data.from.count}
                      </Typography>
                    </td>
                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal"
                      >
                        {data.to.code}
                        {data.to.count}
                      </Typography>
                    </td>
                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal"
                      >
                        {handleDate(data.createdAt)}
                      </Typography>
                    </td>
                    <td className={classes}>
                      <div className="flex items-center space-x-2">
                        <Tooltip content="View Codes">
                          <IconButton
                            variant="text"
                            onClick={() => handleViewCodes(data._id)}
                          >
                            <AiOutlineEye className="h-4 w-4" />
                          </IconButton>
                        </Tooltip>
                      </div>
                    </td>
                  </tr>
                </>
              );
            })}
          </tbody>
        </table>
      </CardBody>
      <CardFooter className="flex items-center justify-center space-x-2 border-t border-blue-gray-50 p-4">
        <Button
          color="green"
          variant="outlined"
          size="sm"
          onClick={handlePreViews}
          disabled={page === 1}
        >
          Previous
        </Button>
        <div className="flex items-center gap-2">
          {Array.from({ length: totalPages }, (_, index) => (
            <IconButton
              key={index}
              variant="outlined"
              color="green"
              size="sm"
              onClick={() => setPage(index + 1)}
              disabled={page === index + 1}
            >
              {index + 1}
            </IconButton>
          ))}
        </div>
        <Button
          color="green"
          variant="outlined"
          size="sm"
          onClick={handleForword}
          disabled={page === totalPages}
        >
          Next
        </Button>
      </CardFooter>
    </Card>
  );
}
